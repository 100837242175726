<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <b-row class="no-gutters">
        <b-col>
          <h1 class="mr-sm-4 header-tablepage">Team</h1>
        </b-col>
      </b-row>
      <div class="title-tabs mt-3">Team Detail</div>
      <b-container class="no-gutters bg-white">
        <div class="py-3">
          <b-row>
            <b-col sm="6">
              <InputText
                textFloat="Name"
                placeholder="Name"
                v-model="form.team.name"
                isRequired
                type="text"
                :isValidate="$v.form.team.name.$error"
                :v="$v.form.team.name"
              />
            </b-col>
          </b-row>
        </div>

        <b-row class="align-items-center justify-content-between">
          <b-col cols="12" class="mb-3">
            <div class="title-tabs">Admin List</div>
          </b-col>

          <b-col lg="4" md="3" sm="6" xs="2" cols="2">
            <button
              type="button"
              class="btn button btn-mobile"
              @click="modalShow()"
            >
              <font-awesome-icon icon="plus" class="text-black d-sm-none" />
              <span class="d-none d-sm-block">
                <font-awesome-icon icon="plus-square" class="pointer" />
                Add Admin
              </span>
            </button></b-col
          >
          <b-col cols="12" class="my-3">
            <b-table
              responsive
              striped
              hover
              :fields="fields"
              :items="form.members"
              :busy="isBusy"
              :per-page="filter.take"
              :current-page="filter.page"
              show-empty
              empty-text="No matching records found"
            >
              <template v-slot:cell(id)="{ item }">
                <b-button variant="custom-link" @click="removeAdmin(item)">
                  <font-awesome-icon icon="trash-alt" />
                </b-button>
              </template>
              <template v-slot:cell(status)="{ item }">
                <div
                  :class="[
                    'd-flex justify-content-center',
                    item.status == 1 ? 'text-success' : 'text-error',
                  ]"
                >
                  {{ item.status == 1 ? "Active" : "Inactive" }}
                </div>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
            <Pagination :filter="filter" :rows="form.members.length" />
            <div class="text-error" v-if="$v.form.members.$error">
              Please select 1 admin at least.
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <ModalAdmin ref="modal" @submit="setAdminList" />
    <FooterAction routePath="/setting/team" @submit="checkHasTeam()" />
    <b-modal v-model="confirmMoveAdminModal" hide-footer hide-header centered>
      <div class="text-black">
        <div class="text-center">
          <font-awesome-icon
            class="icon-alert"
            :icon="['fas', 'exclamation-circle']"
          />
        </div>
        <h3 class="text-center">Are you sure.</h3>
        <h5 class="text-center mb-3">
          Do You want to move admin from another team?
        </h5>
        <hr />
        <template v-for="(list, index) of AdminMoveList">
          <div :key="index + 'move-list'" class="mb-2 f-16">
            Admin Name : <span class="title">{{ list.name }}</span>
            <br />
            Move from
            <span class="text-underline text-error">{{ list.team }}</span> To
            <span class="text-underline text-success">{{
              form.team.name
            }}</span>
            <hr v-if="index !== AdminMoveList.length - 1" />
          </div>
        </template>
      </div>
      <hr />
      <div class="justify-content-center d-flex">
        <b-button
          @click="confirmMoveAdminModal = false"
          variant="outline-secondary"
          class="mr-2 px-4"
          pill
          >Cancel</b-button
        >
        <b-button pill class="px-4" variant="custom-primary" @click="saveForm()"
          >Confirm</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import OtherLoading from "@/components/loading/OtherLoading";
import ModalAdmin from "./components/ModalAdmin";

export default {
  name: "ReceiptDetail",
  components: {
    OtherLoading,
    ModalAdmin,
  },

  data() {
    return {
      isLoading: true,
      form: {
        team: {
          name: "",
        },
        members: [],
      },
      id: this.$route.params.id,
      fields: [
        {
          key: "name",
          label: "Admin Name",
          class: "w-2",
        },
        {
          key: "email",
          label: "Email",
          class: "w-2",
        },
        {
          key: "role",
          label: "Role",
          class: "w-2",
        },
        {
          key: "status",
          label: "Status",
          class: "w-2",
        },
        {
          key: "id",
          label: "",
          class: "w-1",
        },
      ],
      isBusy: false,
      filter: {
        page: 1,
        take: 10,
      },
      confirmMoveAdminModal: false,
      AdminMoveList: [],
    };
  },
  validations() {
    return {
      form: {
        team: {
          name: { required },
        },
        members: {
          required,
        },
      },
    };
  },
  async created() {
    this.getDetail();
  },
  methods: {
    modalShow() {
      this.$refs.modal.show(this.form.members);
    },
    async getDetail() {
      this.isLoading = true;
      if (this.id > 0) {
        const res = await this.axios(`/team/${this.id}`);
        if (res.data.result == 1) {
          this.form = res.data.detail;
        }
      }
      this.isLoading = false;
    },
    checkHasTeam() {
      this.$v.$touch();
      if (this.$v.$error) return;
      this.AdminMoveList = this.form.members.filter(
        (el) => el.team_id != this.id && el.team_id != 0
      );
      if (this.AdminMoveList.length > 0)
        return (this.confirmMoveAdminModal = true);
      this.saveForm();
    },
    async saveForm() {
      this.confirmMoveAdminModal = false;
      this.$bus.$emit("showLoading");
      let payload = { ...this.form };
      payload.members = this.form.members.map((el) => {
        return { ...el, old_team_id: el.team_id };
      });
      const res = await this.axios.post(`/team`, payload);
      if (res.data.result == 1) {
        this.successAlert().then(() => this.$router.push("/setting/team"));
      } else {
        this.errorAlert(res.data.message);
      }
      this.$bus.$emit("hideLoading");
    },
    setAdminList(val) {
      this.form.members = val;
    },
    removeAdmin(id) {
      let index = this.form.members.findIndex(
        (el) => el.user_guid == id.user_guid
      );
      this.form.members.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.ft-16 {
  font-size: 16px;
  color: var(--font-main-color);
}

.bg-gray {
  background-color: #f7f7f7;
}

.text-gray {
  color: #939393;
}

.input-container.error {
  border-color: red !important;
}

// ::v-deep .branch-modal > div {
//   margin: 0px 0px 4px 0px !important;
// }
.condition-title {
  color: var(--primary-color);
  font-size: 16px;
}

.btn-add-condition {
  color: white;
  background-color: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  border: none !important;
}

.panelborder {
  border: 1px solid #d8dbe0;
}

.btn-icon {
  background-color: transparent;
  border: none;
  font-size: 16px;
}

.position-icon {
  position: absolute;
  z-index: 999;
  left: 90px;
}

.icon-alert {
  font-size: 50px;
  margin: 10px 0;
  color: var(--primary-color);
}
</style>
